import React from 'react';
import Head from 'next/head';
import { Layout } from '../src/integrated-components/_common/Layout';
import { PauseTypography } from '../src/components/PauseTypography';
import { useEnhancedRouter } from '../router';
import { PauseTextButton } from '../src/components/PauseTextButton';
import { ChevronLeft, Home } from '../assets/icons/feather/components';

export default function Page() {
  const router = useEnhancedRouter();
  return (
    <>
      <Head>
        <title>Page not found | Pause</title>
      </Head>
      <Layout centered fullPage>
        <div css={() => ({
          height: '80vh',
          display: 'grid',
          justifyContent: 'center',
          textAlign: 'center',
          gridTemplateRows: 'auto',
          gridAutoFlow: 'rows',
          alignItems: 'center',
        })}
        >
          <div css={(theme) => ({
            display: 'grid',
            gridGap: theme.spacing(2),
            gridAutoFlow: 'rows',
            gridTemplateRows: 'auto',
          })}
          >
            <PauseTypography variant="header">
              Two roads diverged in a wood
            </PauseTypography>
            <PauseTypography>
              And you took the one that led you to a page that doesn&apos;t exist.
            </PauseTypography>
            <PauseTypography>
              Sorry about that.
            </PauseTypography>
            <div css={(theme) => ({
              marginTop: theme.spacing(4),
              display: 'grid',
              gridAutoFlow: 'column',
              gridTemplateColumns: '1fr 1fr',
              alignItems: 'center',
              justifyContent: 'center',
              gridGap: theme.spacing(1),
            })}
            >
              {/* @ts-ignore */}
              <PauseTextButton
                css={{ justifyContent: 'center' }}
                onClick={() => router.back()}
              >
                <ChevronLeft />
                Go back
              </PauseTextButton>
              {/* @ts-ignore */}
              <PauseTextButton
                css={{ justifyContent: 'center' }}
                onClick={() => router.push('/')}
              >
                <Home />
                Go home
              </PauseTextButton>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
